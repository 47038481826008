'use client';

import { Icon, Link } from '@theme/components';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ProductItemDefault as ProductItem } from '@theme/views/product-item/templates/default';
import { useRef } from 'react';
import { useLocalization } from '@akinon/next/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from '@theme/hooks/use-window-size';
import 'swiper/swiper-bundle.css';

export default function RecoWithTitleContent({ data }) {
  const { locale } = useLocalization();
  const { width } = useWindowSize();
  const [clientWidth, setClientWidth] = useState<number | null>(null);

  useEffect(() => {
    setClientWidth(width);
  }, [width]);
  const [activeProduct, setActiveProduct] = useState(
    data?.products?.flatMap((item) => item?.products)
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const layoutPage = false;

  const isStart = activeIndex === 0;
  useEffect(() => {
    if (swiperRef.current?.swiper?.params) {
      const slidesPerView =
        activeProduct.length <= 5
          ? Math.floor(swiperRef.current.swiper.params.slidesPerView || 1)
          : Math.ceil(swiperRef.current.swiper.params.slidesPerView || 1);
      const atEnd = activeIndex === activeProduct.length - slidesPerView;
      const notEnoughSlides =
        activeProduct.length < slidesPerView || activeProduct.length < 5;

      setIsEnd(atEnd || notEnoughSlides);

      if (atEnd || notEnoughSlides) {
        swiperRef.current.swiper.slideTo(
          activeProduct.length -
            Math.floor(swiperRef.current.swiper.params.slidesPerView)
        );
      }
    }

    if (activeProduct.length < 5) {
      setIsEnd(true);
    }
  }, [activeIndex, activeProduct, swiperRef]);

  const handlePrevClick = () => {
    const activeProductLength = activeProduct.length === 5 ? false : true;

    if (
      isEnd &&
      activeProduct.length >
        Math.floor(swiperRef.current.swiper.params.slidesPerView) &&
      activeProductLength
    ) {
      swiperRef.current.swiper.slidePrev(
        activeProduct.length -
          Math.floor(swiperRef.current.swiper.params.slidesPerView + 4)
      );
    }

    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
    setActiveIndex(activeIndex - 1);
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    setActiveIndex(activeIndex + 1);
  };

  if (clientWidth === null) {
    return null;
  }

  if (!data?.products || data?.products?.length === 0) {
    return null;
  }
  return (
    <>
      <div className="mt-10 lg:mt-[100px]">
        <div className="mb-3 flex justify-between gap-4 lg:mb-5">
          <div className="text-2xl font-medium">
            {data?.attributes?.reco_with_title_url?.value?.title}
          </div>
          <div className="items-center flex">
            <Link
              className="me-6 w-max font-sans text-sm leading-[1.43] text-primary"
              href={data?.attributes?.reco_with_title_url?.value?.url || '#'}
            >
              {data?.attributes?.reco_with_title_url?.value?.url_title}
            </Link>
            <div className="flex gap-2">
              <button
                onClick={handlePrevClick}
                disabled={isStart}
                className={clsx(
                  'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black',
                  {
                    'cursor-not-allowed opacity-20': isStart
                  }
                )}
              >
                <Icon
                  name={locale?.startsWith('ar') ? 'chevron-right' : 'chevron-left'}
                  size={24}
                />
              </button>
              <button
                onClick={handleNextClick}
                disabled={isEnd}
                className={clsx(
                  'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black',
                  {
                    'cursor-not-allowed opacity-20': isEnd
                  }
                )}
              >
                <Icon
                  name={locale?.startsWith('ar') ? 'chevron-left' : 'chevron-right'}
                  size={24}
                />
              </button>
            </div>
          </div>
        </div>
        <Swiper
          ref={swiperRef}
          spaceBetween={clientWidth < 768 ? 26 : layoutPage ? 16 : 18}
          slidesPerView={
            clientWidth < 480
              ? 1.9
              : clientWidth < 768
                ? 2.2
                : clientWidth < 1024
                  ? 5
                  : 6.5
          }
          className="!relative"
        >
          {data?.products?.map((product, index) => (
            <SwiperSlide key={index}>
              <ProductItem
                key={product.pk}
                product={product}
                ImageWidth={254}
                ImageHeight={238}
                mobileImageWidth={176}
                mobileImageHeight={215}
                index={index}
                wrapperClassName="!rounded-2xl"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
