'use client';

import { FreeMode, Pagination } from 'swiper/modules';
import { Link, SwiperReact, SwiperSlide } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { useRef } from 'react';
import FlatpageSpecialDealsContent from '@theme/views/widgets/flatpage-special-deals-contents';

export default async function FlatpageSpecialDeals({ data }) {
  const { width } = useWindowSize();
  const swiperRef = useRef(null);

  return (
    <div className='mt-10 lg:mt-[100px]'>
      <p className="mb-4 text-2xl font-semibold">{width < 768 ? data?.attributes?.mobile_title?.value : data?.attributes?.title?.value}</p>

      <SwiperReact
        spaceBetween={width < 768 ? 12 : 16}
        slidesPerView={width < 768 ? 2 : 5}
        onInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="!relative"
      >
        {data?.attributes?.special_deals.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <FlatpageSpecialDealsContent data={item} />
            </SwiperSlide>
          );
        })}
      </SwiperReact>
    </div>
  );
}
