'use client';

import { Icon } from '@theme/components';
import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';
import { ProductItemDefault as ProductItem } from '@theme/views/product-item/templates/default';
import { useLocalization } from '@akinon/next/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from '@theme/hooks/use-window-size';
import 'swiper/swiper-bundle.css';

type LandingRecommendationContentProps = {
  recommendationData: any;
  otherContent?: any;
  title?: string;
};

export default function LandingRecommendationContent({
  recommendationData,
  otherContent,
  title
}: LandingRecommendationContentProps) {
  const { locale } = useLocalization();
  const { width } = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef<any>(null);
  const [activeProduct, setActiveProduct] = useState(
    recommendationData && recommendationData?.products?.length
  );
  const calculateSlidesPerView = () => {
    if (width < 768) return 1.8;
    if (width < 1024) return 1.5;
    if (width < 1340) return 3;
    if (!otherContent) return 6.5;
    return 4.2;
  };

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
    setIsEnd(swiper.isEnd);
  };

  const isStart = activeIndex === 0;
  useEffect(() => {
    if (swiperRef.current?.swiper?.params) {
      const slidesPerView =
        activeProduct.length <= 5
          ? Math.floor(swiperRef.current.swiper.params.slidesPerView || 1)
          : Math.ceil(swiperRef.current.swiper.params.slidesPerView || 1);
      const atEnd = activeIndex === activeProduct.length - slidesPerView;
      const notEnoughSlides =
        activeProduct.length < slidesPerView || activeProduct.length < 5;

      setIsEnd(atEnd || notEnoughSlides);

      if (atEnd || notEnoughSlides) {
        swiperRef.current.swiper.slideTo(
          activeProduct.length -
            Math.floor(swiperRef.current.swiper.params.slidesPerView)
        );
      }
    }

    if (activeProduct.length < 5) {
      setIsEnd(true);
    }
  }, [activeIndex, activeProduct, swiperRef]);

  const handlePrevClick = () => {
    const activeProductLength = activeProduct.length === 5 ? false : true;

    if (
      isEnd &&
      activeProduct.length >
        Math.floor(swiperRef.current.swiper.params.slidesPerView) &&
      activeProductLength
    ) {
      swiperRef.current.swiper.slidePrev(
        activeProduct.length -
          Math.floor(swiperRef.current.swiper.params.slidesPerView + 4)
      );
    }

    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
    setActiveIndex(activeIndex - 1);
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    setActiveIndex(activeIndex + 1);
  };

  return (
    <div>
      <div className="mb-5 items-center flex justify-between lg:mt-8">
        <p className="text-2xl font-semibold lg:text-2xl">
          {title}
        </p>
        <div className="flex gap-2">
          <button
            onClick={handlePrevClick}
            disabled={isStart}
            className={clsx(
              'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black',
              {
                'cursor-not-allowed opacity-20': isStart
              }
            )}
          >
            <Icon
              name={locale === 'ar-qa' ? 'chevron-right' : 'chevron-left'}
              size={24}
            />
          </button>
          <button
            onClick={handleNextClick}
            disabled={isEnd}
            className={clsx(
              'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black',
              {
                'cursor-not-allowed opacity-20': isEnd
              }
            )}
          >
            <Icon
              name={locale === 'ar-qa' ? 'chevron-left' : 'chevron-right'}
              size={24}
            />
          </button>
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        spaceBetween={width < 768 ? 20 : 18}
        slidesPerView={calculateSlidesPerView()}
        onSlideChange={handleSlideChange}
        className="!relative"
      >
        {recommendationData.products.map((product, index) => (
          <SwiperSlide key={product.pk}>
            <ProductItem
              product={product}
              ImageWidth={254}
              ImageHeight={238}
              mobileImageWidth={176}
              mobileImageHeight={215}
              index={index}
              imageUrl={product.image}
              wrapperClassName="!rounded-2xl"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
