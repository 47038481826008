'use client';

import { Link, SwiperPagination, SwiperReact, SwiperSlide, SwiperAutoplay, SwiperNavigation } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { useGetBasketQuery } from '@akinon/next/data/client/basket';
import { trackBannerClick } from '@theme/utils/emarsys';
import { useMemo, useCallback } from 'react';

export default function HomeSlider({ data }: { data: any }) {
  const { data: session } = useSession();
  const { data: basket } = useGetBasketQuery();

  const handleEmarsysBannerClick = useCallback((item) => {
    const banner = {
      'Simple Banner': {
        bannerId: 'Simple Banner Test',
      },
    };
    trackBannerClick(session, banner, basket?.basketitem_set);
  }, [session, basket?.basketitem_set]);

  const leftBarItems = useMemo(() => (
    data?.attributes?.home_slider_box_left.map((item, i) => (
      <Link key={i}
        href={item?.value?.url}
        target={item?.value?.is_target_blank == 'True' ? '_blank' : '_self'}
        className='h-[185px]'>
        <Image
          className='w-full h-[185px] rounded-2xl'
          src={item?.kwargs?.value?.image.url}
          alt="Box Image"
          draggable={false}
          imageClassName="rounded-2xl h-full w-full"
          onClick={() => handleEmarsysBannerClick(item)}
          sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
          aspectRatio={236 / 185}
          fill
        />
      </Link>
    ))
  ), [data?.attributes?.home_slider_box_left, handleEmarsysBannerClick]);

  const sliderItems = useMemo(() => (
    data?.attributes?.home_slider_image.filter((item) => !(session?.user && item?.value.is_logged_in == 'True')).map((item, i) => (
      <SwiperSlide key={i}>
        <div className="contain-hero-slider relative">
          <Link
            target={item?.value?.is_target_blank == 'True' ? '_blank' : '_self'}
            href={item?.value?.url}>
            {item?.value?.badge && (
              <div className="absolute top-10 left-12 rounded-[24px] z-20 text-md py-3 px-5 font-semibold leading-[1.14] text-black  lg:text-base lg:leading-[1] hidden md:flex bg-[#cdf9e1]">
                {item.value.badge}
              </div>
            )}
            {item?.value?.title && item?.value?.title != 'not-logged-in' && (
              <h2 className="text-[70px] text-white absolute bottom-[74px] left-12 z-20 w-10/12 hidden md:block leading-none font-bold">
                {item.value.title}
              </h2>
            )}
            <Image
              src={item?.kwargs?.value?.image?.url}
              alt="Slider Image"
              aspectRatio={818 / 391}
              sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
              imageClassName="lg:h-[390px] h-auto"
              className="h-auto md:h-[391px]"
              fill
            />
          </Link>
        </div>
      </SwiperSlide>
    ))
  ), [data?.attributes?.home_slider_image, session?.user]);

  return (
    <div className="container mx-auto mt-6">
      <div className="vertical-container grid grid-cols-12 xl:grid-cols-[236px_auto_236px] lg:gap-5 gap-[15px] flex-col md:flex-row">

        <div className="mobile-logo w-full flex lg:hidden justify-center items-center col-span-12">
          {data?.attributes?.home_mobil_logo?.map((item, i) => (
            <Link key={i}
              href={item?.value?.url}
              target={item?.value?.is_target_blank == 'True' ? '_blank' : '_self'}
              className="border border-[#5b1c9a1f] w-full flex justify-center items-center rounded-2xl">
              <Image
                className="rounded-2xl"
                src={item?.kwargs?.value?.image.url}
                alt="Mobile Logo"
                draggable={false}
                imageClassName="rounded-2xl h-[120px] w-full object-fit"
                sizes="100wv"
                aspectRatio={788 / 120}
                fill
              />
            </Link>
          ))}
        </div>

        <div className="left-bar hidden lg:flex flex-col gap-5 col-span-3 xl:col-auto">
          {leftBarItems}
        </div>

        <div className="slider relative col-span-12 lg:col-span-6 xl:col-auto xl:max-w-[610px] 2xl:max-w-[818px] lg:h-full">
          <SwiperReact className="md:h-[391px] rounded-2xl relative aspect-[818/391] w-full"
            modules={[SwiperPagination, SwiperAutoplay, SwiperNavigation]}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
              bulletClass: 'swiper-pagination-bullet',
              bulletActiveClass: 'swiper-pagination-bullet-active !w-[70px] !h-[8px] !rounded-[10px]',
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            {sliderItems}
            {data?.attributes?.home_slider_image.length > 1 && (
              <div className={clsx('swiper-pagination mb-[-3px] lg:mb-[9px]')}></div>
            )}
            <div className="swiper-button-next !text-white"></div>
            <div className="swiper-button-prev !text-white"></div>
          </SwiperReact>
        </div>

        <div className="right-bar hidden lg:flex flex-col gap-5 col-span-3 lg:items-end xl:col-auto">
          {data?.attributes?.home_slider_box_right.map((item, i) => (
            <Link
              key={i}
              target={item?.value?.is_target_blank == 'True' ? '_blank' : '_self'}
              href={item?.value?.url} className="lg:h-[185px]">
              <Image
                className="w-full h-[185px] rounded-2xl"
                src={item?.kwargs?.value?.image.url}
                alt="Right Banner"
                draggable={false}
                sizes="236px"
                aspectRatio={236 / 185}
                imageClassName="rounded-2xl h-full w-full"
                fill
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
