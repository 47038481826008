import { useState, useRef, useEffect } from 'react';
import { Image } from '@akinon/next/components';
import { Icon } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { Link } from '@theme/components';

export default function FlatpageSpecialDealsContent({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [mounted, setMounted] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 768;


  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || !data?.value) return null;

  const fileUrl = isMobile
  ? data?.kwargs?.value?.mobile_file?.url
  : data?.kwargs?.value?.file?.url;

  const altText = data.value.file_alt_text;
  const redirectUrl = data.value.redirect_url;

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      isPlaying ? video.pause() : video.play();
      setIsPlaying(!isPlaying);
    }
  };

  if (!fileUrl) {
    console.warn('No file URL provided to FlatpageSpecialDealsContent');
    return null;
  }

  const VideoContent = () => (
    <div className="relative h-[298px] rounded-2xl lg:h-[414px]">
      <video
        ref={videoRef}
        className="hidden h-full w-full rounded-2xl object-cover lg:block"
        width={255}
        height={414}
        src={fileUrl}
        onEnded={() => setIsPlaying(false)}
        muted
      />
      <video
        ref={videoRef}
        className="block h-full w-full rounded-2xl object-cover lg:hidden"
        width={255}
        height={298}
        src={fileUrl}
        onEnded={() => setIsPlaying(false)}
        muted
      />
      {isPlaying ? (
        <div
          className="absolute bottom-0 start-0 flex cursor-pointer items-center justify-center opacity-70 hover:scale-125 hover:opacity-100"
          onClick={toggleVideoPlayback}
        >
          <Icon name="video-pause" size={40} className="z-10 text-white" />
        </div>
      ) : (
        <div
          className="absolute inset-0 flex cursor-pointer items-center justify-center"
          onClick={toggleVideoPlayback}
        >
          <div className="absolute inset-0 rounded-2xl bg-[#838383] opacity-40"></div>
          <Icon name="video-play" size={40} className="z-10 text-white" />
        </div>
      )}
    </div>
  );

  const ImageContent = () => {
    const ImageComponent = () => (
      <>
        <Image
          imageClassName="rounded-2xl object-cover h-[414px] h-full hidden lg:block"
          className="rounded-2xl"
          width={255}
          height={414}
          src={fileUrl}
          alt={altText}
        />
        <Image
          imageClassName="rounded-2xl object-cover h-[298px] block lg:hidden"
          className="rounded-2xl"
          width={255}
          height={298}
          src={fileUrl}
          alt={altText}
        />
      </>
    );

    return redirectUrl ? (
      <Link href={redirectUrl}>
        <ImageComponent />
      </Link>
    ) : (
      <ImageComponent />
    );
  };

  return (
    <div className="relative flex h-[298px] overflow-hidden rounded-2xl lg:h-[414px]">
      {fileUrl?.includes('.mp4') ? <VideoContent /> : <ImageContent />}
    </div>
  );
}
