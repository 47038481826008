'use client';

import { FlatPageFeaturedCategoriesContentValue } from '@theme/types';
import { Image, Link } from '@akinon/next/components';

export default function Item({
  item
}: {
  item: FlatPageFeaturedCategoriesContentValue;
}) {
  return (
    <Link href={item?.value?.url || '#'}>
      <div className="flex flex-col items-center justify-evenly [box-shadow:0px_4px_15px_0px_#0000000D]
      bg-[linear-gradient(0deg,_#FAFBFC,_#dff7f0),linear-gradient(180deg,_rgba(221,_237,_229,_0.9)_0%,_rgba(250,_251,_252,_0.9)_100%)]
      h-[154px] w-[144px] rounded-[24px] bg-opacity-10">
        <>
          <Image
            src={item?.kwargs?.value?.image?.url}
            alt={item?.value?.alt || ''}
            width={92}
            className="block"
            height={89}
            imageClassName='w-[92px] h-[89px]'
          />
        </>
        <span className="text-center font-sans text-base font-bold">
          {item?.value?.title}
        </span>
      </div>
    </Link>
  );
}
