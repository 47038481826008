'use client';

import { HomePageFeaturedCategoriesContentValue } from '@theme/types';
import { Image, Link } from '@akinon/next/components';

export default function Item({
  item
}: {
  item: HomePageFeaturedCategoriesContentValue;
}) {
  return (
    <Link className='col-span-3 md:col-span-1' href={item?.value?.url || '#'}>

    <div className="flex flex-col lg:gap-[15px] gap-1.5 justify-center items-center">
      <div className="flex items-center justify-center lg:h-[100px] lg:w-[100px] xl:w-[140px] xl:h-[140px] rounded-[18px] lg:rounded-[24px] bg-primary bg-opacity-10 h-[66px] w-[65px] mb-1 lg:mb-0">
        <div className='w-[55px] h-[55px] lg:w-[90px] lg:h-[90px] xl:w-[130px] xl:h-[130px]'>
          <Image
            src={item?.kwargs?.value?.image?.url}
            alt={item?.value?.alt || ''}
            className="hidden lg:block"
            aspectRatio={130 / 130}
            sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
            fill
          />
          <Image
            src={item?.kwargs?.value?.image?.url}
            alt={item?.value?.alt || ''}
            className="block lg:hidden"
            aspectRatio={55 / 56}
            sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
            fill
          />
        </div>
      </div>
      <span className="font-sans text-sm lg:text-base font-normal leading-4 text-center">
        {item?.value?.title}
      </span>
    </div>
    </Link>
  );
}
