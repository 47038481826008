'use client';

import SpecialDealsContent from '@theme/views/widgets/special-deals-content';
import { SwiperReact, SwiperSlide } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { useRef } from 'react';

const slidesPerViewConfig = {
  xsmall: 1.10,
  small: 1.2,
  medium: 1.4,
  large: 1.8,
  xlarge: 2.15,
  xxlarge: 5
};

const getSlidesPerView = (width) => {
  if (width < 450) return slidesPerViewConfig.xsmall;
  if (width >= 450 && width < 520) return slidesPerViewConfig.small;
  if (width >= 520 && width < 600) return slidesPerViewConfig.medium;
  if (width >= 600 && width < 750) return slidesPerViewConfig.large;
  if (width >= 750 && width < 1024) return slidesPerViewConfig.xlarge;
  return slidesPerViewConfig.xxlarge;
};

export default function SpecialDeals({ data }) {
  const { width } = useWindowSize();
  const swiperRef = useRef(null);

  return (
    <div className="pe-0 ps-6 lg:container">
      <p className="mb-4 text-base font-semibold lg:text-2xl">
        {width < 768
          ? data?.attributes?.mobile_title?.value
          : data?.attributes?.title?.value}
      </p>

      <SwiperReact
        spaceBetween={width < 768 ? 12 : 16}
        slidesPerView={getSlidesPerView(width)}
        onInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="!relative"
      >
        {data?.attributes?.special_deals.map((item, index) => (
          <SwiperSlide key={index}>
            <SpecialDealsContent data={item} />
          </SwiperSlide>
        ))}
      </SwiperReact>
    </div>
  );
}
