'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';
import { useMediaQuery } from '@akinon/next/hooks';
import { useEffect, useState } from 'react';
import { WidgetListItemType } from './types';
import LazyComponent from '@akinon/next/components/lazy-component';
import { getCookie } from '@akinon/next/utils';

export const Container = ({
  children,
  widget,
  useLazy
}: {
  children: ReactNode;
  widget: WidgetListItemType;
  useLazy: boolean;
}) => {
  const matches = useMediaQuery('(min-width: 768px)');
  const [matchesStatus, setMatchesStatus] = useState(false);
  const [userSegment, setUserSegment] = useState('')

  useEffect(() => {
    setUserSegment(getCookie('theme') || 'scheduled')
  }, [])

  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);

  if (userSegment !== widget.value.segment && widget.value.segment !== 'both') {
    return null;
  }

  const style = {};

  const className = {};

  const backgroundStyle = {};

  if (widget.value.visibility === 'both') {
    className['block'] = true;
  } else if (widget.value.visibility === 'mobile') {
    className['lg:hidden'] = true;
  } else if (widget.value.visibility === 'desktop') {
    className['hidden lg:block'] = true;
  } else {
    className['block'] = false;
  }

  if (!matchesStatus) {
    if (widget.value.padding_mobile) {
      style['padding'] = `${widget.value.padding_mobile}`;
    }
  } else {
    if (widget.value.padding_desktop) {
      style['padding'] = `${widget.value.padding_desktop}`;
    }
  }

  if (widget.value.background_color) {
    backgroundStyle['backgroundColor'] = widget.value.background_color;
  }

  return (
    <div style={backgroundStyle}>
      <div className={widget.value.is_contained === 'yes' ? 'container' : ''}>
        <div style={style} className={clsx(className ?? '', `wgt-${widget.value.item_slug}`)}>
          {useLazy ? <LazyComponent>{children}</LazyComponent> : children}
        </div>
      </div>
    </div>
  );
};
