'use client';

import React from 'react';
import { Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';

export default function LandingMultipleSliderContenties({ data }) {
  const imageRatio = {
    mobile: 375 / 171,
    desktop: 1340 / 310
  };
  const sliderItem = data?.attributes?.landing_single;

  if (!sliderItem) {
    return null;
  }

  return (
    <section className="m-auto mt-10 lg:mt-[100px] lg:max-w-full lg:px-0">
      <Link href={sliderItem?.value?.url ?? '#'}>
        {sliderItem?.kwargs?.value?.mobile_image?.url && (
          <Image
            src={sliderItem?.kwargs?.value?.mobile_image?.url}
            alt={sliderItem?.value?.alt}
            aspectRatio={imageRatio.mobile}
            draggable={false}
            width={380}
            height={159}
            className="block md:hidden"
            imageClassName="rounded-2xl w-[380px] h-[159px] object-cover"
          />
        )}

        {sliderItem?.kwargs?.value?.image?.url && (
          <Image
            src={sliderItem?.kwargs?.value?.image?.url}
            alt={sliderItem?.value?.alt}
            aspectRatio={imageRatio.desktop}
            draggable={false}
            width={1340}
            height={310}
            className="hidden md:block"
            imageClassName="rounded-2xl w-[1340px] h-[210px] object-cover"
          />
        )}
      </Link>
    </section>
  );
}
