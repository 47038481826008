'use client';

import React from 'react';
import { CarouselCore } from '@theme/components/carousel-core';
import { Image, Link } from '@akinon/next/components';

export default function HomeHeroSliderContent({ data }) {
  const imageRatio = {
    mobile: 375 / 540,
    desktop: 1540 / 540
  };

  return (
    <CarouselCore
      responsive={{
        all: {
          breakpoint: { max: 5000, min: 0 },
          items: 1
        }
      }}
      arrows={false}
      swipeable={true}
      containerAspectRatio={{
        mobile: imageRatio.mobile,
        desktop: imageRatio.desktop
      }}
    >
      {data?.attributes?.hero_slider?.map((item, i) => (
        <div key={i}>
          <Link href={item?.value?.url}>
            <Image
              src={item?.kwargs?.value?.mobile_image?.url}
              alt={item?.value?.alt}
              aspectRatio={imageRatio.mobile}
              draggable={false}
              sizes="375px"
              fill
              className="block md:hidden"
            />
            <Image
              src={item?.kwargs?.value?.image?.url}
              alt={item?.value?.alt}
              aspectRatio={imageRatio.desktop}
              draggable={false}
              sizes="1540px"
              fill
              className="hidden md:block"
            />
          </Link>
        </div>
      ))}
    </CarouselCore>
  );
}
