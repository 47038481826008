'use client';

import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Carousel, {
  CarouselProps as BaseCarouselProps
} from 'react-multi-carousel';
import { forwardRef } from 'react';
import { useMediaQuery } from '@akinon/next/hooks';

type ContainerAspectRatioType = {
  mobile: number;
  desktop: number;
};

interface CarouselProps extends BaseCarouselProps {
  containerAspectRatio: ContainerAspectRatioType;
}

const CarouselCore = forwardRef<Carousel, CarouselProps>((props, ref) => {
  const { containerAspectRatio } = props;
  const matches = useMediaQuery('(min-width: 768px)');
  const [aspectRatio, setAspectRatio] = useState(containerAspectRatio.mobile);

  useEffect(() => {
    if (matches) {
      setAspectRatio(containerAspectRatio.desktop);
    } else {
      setAspectRatio(containerAspectRatio.mobile);
    }
  }, [matches, containerAspectRatio]);

  return (
    <div className="w-full" style={{ aspectRatio }}>
      <Carousel {...props} ref={ref} />
    </div>
  );
});

CarouselCore.displayName = 'CarouselCore';

export { CarouselCore };
