'use client';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const SliderButton = (props) => {
  return (
    <div
      {...props}
      className={twMerge(
        clsx(
          'cursor-pointer flex-center h-11 rounded-full border border-primary bg-primary px-5 text-sm text-primary-foreground transition-all hover:border-primary hover:bg-white hover:text-primary',
          props.appearance === 'outlined' &&
            'bg-transparent text-primary hover:bg-primary hover:text-primary-foreground',
          props.appearance === 'ghost' &&
            'border-transparent bg-transparent text-primary hover:bg-primary hover:text-primary-foreground'
        ),
        props.className
      )}
    >
      {props.children}
    </div>
  );
};
